<template>
    <div class="row">
        <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Re-order Paragraphs
                </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Re-order Paragraphs</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You'll get text, read that text and answer the multiple-choice question by selecting all the correct responses. More than one response is correct.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Reading</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 2 - 3 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>You will be presented with several paragraphs in a random order. You need to drag the paragraphs from the left side and put them in order into the blank space on the right hand side.</li>
                <li>Every well-written paragraph has a topic sentence. It is usually a clear statement about the topic, and all the other sentences are related in some way to this sentence.</li>
                <li>If you have time period mentioned in your paragraphs, the chronological order may help you to set paragraphs in the right order.</li>
            </ul>
            </template>
            <app-ReadingExtraInfo />
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
            <h4>Re-order Paragraphs</h4>
            <p class="questHeading">The text boxes in the below panel have been placed in a random order. Restore the original order by dragging the text boxes up and down and submit your final answer.
                </p>
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>Time </p><app-Timer ref="timer" :increment="true"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0">
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.question_id" :idName="'question_id'" :tableName="'tblReOrderParagraph'"/>
            </div>
            </div>
            <ul class="collection">
            <draggable :list="selectedquestion" class="dragArea" @end="onEnd">
            <li v-for="(task) in selectedquestion" :key="task.reorder_id" :id="task.reorder_id" class="collection-item">
            <strong>{{task.reorder_id}}) </strong>    
                    {{task.reorder_desc}}
            </li>
            </draggable>
            </ul>

            <div class="row">
                <div class="col-12 text-left" style="background: #001848;" v-if="this.userAnswer.answers">
                    <p style="padding-left:0px!important;color:#fff;"><strong>Your Score:   <span class="pl-1">{{score_count}}</span>/{{selectedquestion.length-1}}</strong></p>
                </div>
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 pl-0 mb-2">
                    <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button>
                    <!-- <b-button :disabled="apiResults ==null" variant="success" class="mr-2 pl-4 pr-4" v-b-modal.result-Modal>
                        <b-spinner v-if="resultStatus=='In-Process'" small type="grow"></b-spinner>
                        {{resultStatus}}
                    </b-button> -->
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p class="rightAnswer" style="display:block;padding-bottom: 0px !important;">{{correctAnswer}}</p>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-12 mb-2 mt-2 results" v-if="checkAnswerFlag == true">
                    <h5>Result:- </h5>
                    <p><strong>Your result is {{this.userAnswer.user_score}} out of {{selectedquestion.length-1}}</strong></p>
                    <h5 class="mt-2"><strong>Correct Sequence:- 
                        <span>{{correctAnswer}}</span>
                        </strong></h5>
                </div>
                <div class="col-12 text-center">
                    <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>
                </div>
            </div> -->
        </div>
        <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'question_id'"/></b-container>
        </b-modal>
        <b-modal id="dictionery-Modal" size="lg" scrollable centered hide-footer hide-header @hidden="hideModal" ok-only ok-title="Cancel" ok-variant="secondary">
         <b-container class="" v-if="selectedquestion!=null">
              <app-dictTemp :templatePara="dictText" :title="modelTitle"/>
         </b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserReOrderParagraph'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import draggable from 'vuedraggable'
import $ from "jquery";
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import dictTemplate from '../dictionerymode/dictioneryTemplate'
import DiscussionTab from '../reuseable/discussionTab'
import ReadingExtraInfo from '../reuseable/readingInformation.vue'
//import LocalStorageService from '../../store/LocalStorageService';
export default {
    components: {
            draggable,
            'app-Timer':Timer,
            'app-IntervalList': IntervalList,
            'app-dictTemp': dictTemplate,
            'app-QuestionStatus':QuestionStatus,
            'app-DiscussionTab':DiscussionTab,
            'app-ReadingExtraInfo':ReadingExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            correctAnswer:'',
            paraLists:null,
            checkAnswerFlag:false,
            userAnswerResponse:null,
            userAnswer:{
                answers:null,
                url:'',
                user_id:0,
                question_id:0,
                user_score: '',
                mock_test_id:0
            },
            wrong:false,
            orderSeq:[],
            score_count:0,
            user_type:'Free',
            currentQuestion:0,
            searchKey:'',
            searchQuestionList:null,
            isShowModal: false,
             modelTitle:'heading',
            dictText:''
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'writtingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
            answerResponse: 'writtingmodule/AnswerResponse'
        }),
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        showModal() {
            for (let index = 0; index < this.selectedquestion.length; index++) {
                const element = this.selectedquestion[index].reorder_desc;
                this.dictText +=element
                this.dictText+='<br>'
            }
            this.isShowModal = true;
            this.$root.$emit('bv::show::modal', 'dictionery-Modal')
            this.$refs.timer.pauseTime()
            },
        hideModal() {
            this.isShowModal = false
            this.$refs.timer.resumeTime()
            },
        reset(){
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                answers:null,
                url:'',
                question_id:0,
                user_score: '',
                mock_test_id:0
            }
             this.correctAnswer=''
            this.checkAnswerFlag = false
            this.score_count=0
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            this.reset()
            this.userAnswer.question_id = questionId;
            this.selectedquestion = this.paraLists.filter(function (item) {
                    return item.question_id == questionId;
                });
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
                var vm= this
            $.each(this.selectedquestion,function(index,value){
                vm.correctAnswer += value.order_seq+', '
            })
            this.modelTitle = this.selectedquestion[0].title
            //console.log(this.selectedquestion)
            this.$nextTick(function(){
                this.$refs.discussionTab.updateQuestionId(questionId)
                this.$refs.timer.setTime(0)
            })
            this.removeClasses()
        },
        redoQuest(){
            this.getQuestionDetail(this.currentQuestion)
        },
        async checkAnswer(){
            var vm = this;
            var lastCorrect=false;
            this.score_count=0
            vm.removeClasses()
            //var seq = 1
            var correctAnswerArray= this.correctAnswer.split(',')
            vm.checkAnswerFlag = true
            var currentPara=false
            $('.collection li').each(function(index,item){
                const currentPosition = parseInt(item.id)
                //console.log(currentPosition +'=='+correctAnswerArray[index])
                if(currentPosition == correctAnswerArray[index]){
                    $('#'+item.id).addClass('rightAnswer')
                    if(currentPara & lastCorrect){
                        vm.score_count++
                    }
                    lastCorrect=currentPara = true
                }
                else{
                    $('#'+item.id).addClass('wrongAnswer')
                    lastCorrect=currentPara = false
                }
                //seq++
            })
                
                // if(vm.score_count < 2 ) this.currentClass ='text-danger'
                // else if(vm.score_count <=(this.selectedquestion.length-1)-1) this.currentClass ='text-warning' 
        },
        removeClasses(){
            $('li.wrongAnswer').removeClass('wrongAnswer')
            $('li.rightAnswer').removeClass('rightAnswer')
        },
        onEnd(){
             //console.log("onEnd")
            //if(this.checkAnswerFlag) this.checkAnswer()
        },
        timerEnd(){
            //console.log("end of time")
        },
        async submitanswer() {
            var seq = 1
            var vm = this;
            
            await vm.checkAnswer();
            this.orderSeq = [];
            this.checkAnswerFlag = true
            $('.collection li').each(function(index,item){
                const currentPosition = parseInt(item.id)
                const tempCt= {
                    reorder_id: seq,
                    order_seq: currentPosition
                }
                vm.orderSeq.push(tempCt);
                seq++
            });
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.userAnswer.mock_test_id=0
            this.userAnswer.user_score = 'Your Score: '+ this.score_count + '/' + (this.selectedquestion.length-2);
            // //console.log(this.score_count)
            // //console.log(this.selectedquestion.length-1)
            // //console.log('Your Score: '+this.score_count + '/' + (this.selectedquestion.length-1))
            this.userAnswer.url = 'userreorderpara/createuserparagraph';
            this.userAnswer.answers = this.orderSeq;
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    this.userAnswerResponse = this.answerResponse().data
                });
        }
    },
    beforeMount(){
        var vm = this;
        this.questionservice('configreorderpara/getallparagraphs').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            this.questionservice('configreorderpara/getallungroupedparagraphs').then(()=>{
                vm.paraLists = this.getQuestionList().data
                //console.log(vm.paraLists)
            });
        });
    }
}
</script>
<style scoped>
.collection{
    padding-left: 0;
    list-style-type: none;
    margin: .5rem 0 1rem 0;
    /* border: 1px solid #e0e0e0; */
    border-radius: 2px;
    overflow: hidden;
    position: relative;
}
.collection li{
    background-color: #f9f9f9;
    line-height: 1.5rem;
    padding: 15px 20px;
    margin: 0;
    border: 1px dashed #a4a1a1;
    margin-bottom: 20px;
    border-radius: 2px;
    cursor: grab;
}
.collection li:active, .collection li:focus{
    background-color: #ccc;
}
.collection li strong{
    padding-right:5px ;
}
.text-center button{
    margin-right:30px
}
</style>